import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { GRAPH_LABEL_LENGTH_LIMIT } from '../Pages/CTData/const';
import getWidthOfText from '../utils';
import Plot from 'react-plotly.js';

interface HorizontalBarGraphProps {
  data: Array<any>;
}

const PlotComponent = Plot as any;

const HorizontalBarGraph: React.FC<HorizontalBarGraphProps> = ({
  data
}: HorizontalBarGraphProps) => {
  const [fontSize, setFontSize] = useState(140);
  useEffect(() => {
    try {
      const xValues = [...(data?.[0]?.x || [])];
      xValues.length = Math.min(25, xValues.length);
      let maxLengthString =
        xValues.reduce(
          (maxlengthstring: string, curr: string) =>
            curr.length > maxlengthstring.length ? curr : maxlengthstring,
          ''
        ) || '';
      if (maxLengthString.length > GRAPH_LABEL_LENGTH_LIMIT) {
        const maxLengthStringArray = maxLengthString.split('');
        maxLengthStringArray.length = GRAPH_LABEL_LENGTH_LIMIT;
        maxLengthString = maxLengthStringArray.join('');
      }
      let calculatedSize = Math.ceil(getWidthOfText(maxLengthString, 14) * 0.707 + 1);
      if (calculatedSize === 0) {
        calculatedSize = 140;
      }
      calculatedSize = Math.max(40, calculatedSize);
      setFontSize(calculatedSize);
    } catch (err) {
      setFontSize(140);
    }
  }, [data]);

  return (
    <Box
      display='flex'
      width='100%'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'>
      <PlotComponent
        style={{ height: '100%', width: '100%' }}
        data={data}
        useResizeHandler
        layout={{
          autosize: true,
          margin: { t: 32, r: 140, b: fontSize },
          xaxis: {
            range: [-0.5, 25],
            tickangle: 45
          },
          orientation: 90,
          dragmode: 'pan'
        }}
        config={{
          displayModeBar: false // this is the line that hides the bar.
        }}
      />
    </Box>
  );
};

export default React.memo(HorizontalBarGraph);
