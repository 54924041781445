import * as React from 'react';

import { Container } from '@mui/material';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import theme from './theme';
import NavBar from './Component/Navbar/Navbar';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from './Component/Footer';
import Logobar from './Component/Navbar/Logobar';
import Headliner from './Component/Headliner';
import FooterSection from './Pages/Recon2025/Footer/FooterSection';
import { HelmetProvider, Helmet } from 'react-helmet-async';

export default function App() {
  const location = useLocation();

  const isOldRecon =
    location.pathname.startsWith('/recon2024') || location.pathname.startsWith('/recon2023');
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Helmet>
          <meta charSet='utf-8' />
          <title>Vivpro | Varsity</title>
          <meta
            name='description'
            content='Vivpro varsity - A step closer to your dream. Vivpro Varsity provides cutting-edge virtual courses, live events, and free content to help you on your way to becoming a professional R&D strategist.'
          />
          <meta name='robots' content='noindex,nofollow' />
          <meta name='google' content='nositelinkssearchbox' />
        </Helmet>

        <Box sx={{ backgroundColor: isOldRecon ? 'black.dark' : 'none' }}>
          <Headliner />
          {!location.pathname.startsWith('/recon') && (
            <Container
              component='main'
              maxWidth={location.pathname.startsWith('/resources/ct') ? false : 'desktop'}
              disableGutters={location.pathname.startsWith('/resources/ct')}>
              {location.pathname.startsWith('/resources/ct') ? <Logobar /> : <NavBar />}

              <Outlet />
              <Footer />
            </Container>
          )}
          {location.pathname.startsWith('/recon') && (
            <Container
              component='main'
              sx={{
                overflowX: !isOldRecon ? 'hidden' : 'auto'
              }}
              maxWidth={isOldRecon ? 'desktop' : false}
              disableGutters={isOldRecon ? false : true}>
              <NavBar />
              <Outlet />
              {location.pathname === '/recon' || location.pathname.startsWith('/recon/') ? <FooterSection /> :  <Footer />}
            </Container>
          )}
        </Box>

    </ThemeProvider>
    </HelmetProvider>
  );
}
